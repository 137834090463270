<template>
  <section id="services" class="flex flex-col items-center lower-section">
    <h2>Services</h2>

    <h3 class="text-zinc-600">What I can do for you</h3>

    <div
      id="services-wrapper"
      class="flex flex-row flex-wrap justify-center mt-9 max-w-screen-2xl"
    >
      <div
        v-for="(service, index) in services"
        :key="index"
        class="flex flex-col text-center items-center w-80 lg:w-2/5 m-9"
      >
        <div>
          <div class="inline-block rounded-full p-3 bg-orange-400 mb-2">
            <svg
              class="w-8 h-8"
              width="32"
              height="32"
              :viewBox="service.viewbox"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path :d="service.svg" fill="#E4E4E7" />
            </svg>
          </div>
        </div>

        <Waypoint
          :options="options"
          @change="fadeInServiceSnippet"
        >
          <details open @click.prevent>
            <summary>
              {{ service.header }}
            </summary>
            <p class="service-snippet text-zinc-200">
              {{ service.snippet }}
            </p>
          </details>
        </Waypoint>
      </div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from "vue-waypoint";

export default {
  name: "ServicesComponent",
  components: { Waypoint },
  setup() {

    const options = {
      root: document.getElementById("services-wrapper"),
      rootMargin: "0px",
      threshold: 0.75
    }

    const fadeInServiceSnippet = (waypointState) => {
      if (waypointState.going === "IN" && waypointState.el.getElementsByTagName("p")[0].classList.contains("text-zinc-200")) {
        let snippet = waypointState.el.getElementsByTagName("p")[0];
        snippet.classList.remove("text-zinc-200");
        snippet.classList.add("fade-in-service-snippet");
        snippet.classList.add("text-zinc-600");
      }
    };

    const services = [
      {
        header: "Quality Software",
        snippet:
          "A number of programming languages. Source control. Integrated Development Environments (IDEs). Object-Oriented Principles (OOP) and best practices.",
        svg: "M516 673c0 4.4 3.4 8 7.5 8h185c4.1 0 7.5-3.6 7.5-8v-48c0-4.4-3.4-8-7.5-8h-185c-4.1 0-7.5 3.6-7.5 8v48zm-194.9 6.1l192-161c3.8-3.2 3.8-9.1 0-12.3l-192-160.9A7.95 7.95 0 0 0 308 351v62.7c0 2.4 1 4.6 2.9 6.1L420.7 512l-109.8 92.2a8.1 8.1 0 0 0-2.9 6.1V673c0 6.8 7.9 10.5 13.1 6.1zM880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z",
        viewbox: "0 0 1024 1024",
      },
      {
        header: "Web Development",
        snippet:
          "Beautiful web sites. Mobile-first design. Single Page Applications (SPAs). Responsive design. Great performance. Search Engine Optimization (SEO). A variety of hosting services.",
        svg: "M409.6,0H102.4C88.26,0,76.8,11.46,76.8,25.6v460.8c0,14.14,11.46,25.6,25.6,25.6h307.2c14.14,0,25.6-11.46,25.6-25.6 V25.6C435.2,11.46,423.74,0,409.6,0z M409.6,486.4H102.4V25.6h307.2V486.4z M128,51.2v358.4h256V51.2H128z M358.4,384H153.6V76.8h204.8V384z",
        viewbox: "0 0 512 512",
      },
      {
        header: "APIs",
        snippet:
          "REpresentational State Transfer (REST). API creation and consumption. Microservices architecture. JSON. SOAP. XML. You name it.",
        svg: "M381.675,20.803c-20.584,0-37.33,16.747-37.33,37.331c0,4.819,0.951,9.628,2.772,14.085l-61.297,61.297 c-15.555-11.566-34.813-18.42-55.642-18.42c-16.635,0-32.861,4.426-47.175,12.833l-38.512-44.224 c2.607-5.18,3.973-10.901,3.973-16.731c0-20.58-16.745-37.323-37.327-37.323c-20.582,0-37.327,16.743-37.327,37.323 c0,20.584,16.745,37.331,37.327,37.331c3.89,0,7.699-0.594,11.365-1.77l38.083,43.729c-15.413,17.185-23.852,39.139-23.852,62.276 c0,9.273,1.376,18.442,4.094,27.305l-83.869,59.693c-5.893-3.667-12.614-5.59-19.63-5.59C16.745,289.949,0,306.692,0,327.273 c0,20.584,16.745,37.33,37.328,37.33c20.582,0,37.327-16.746,37.327-37.33c0-2.755-0.303-5.486-0.904-8.159l79.983-56.924 c10.309,14.638,24.691,26.114,41.197,32.872l-8.918,25.146c-18.973,1.734-33.912,17.78-33.912,37.17 c0,20.582,16.745,37.326,37.327,37.326c20.582,0,37.328-16.744,37.328-37.326c0-10.949-4.855-21.33-13.139-28.4l9.668-27.26 c2.343,0.178,4.65,0.268,6.893,0.268c11.969,0,23.61-2.249,34.657-6.691l40.105,48.345c-2.775,5.309-4.229,11.201-4.229,17.227 c0,20.584,16.743,37.331,37.322,37.331c20.585,0,37.332-16.747,37.332-37.331c0-20.58-16.747-37.323-37.332-37.323 c-3.691,0-7.318,0.539-10.822,1.606l-37.223-44.87c21.434-17.844,33.632-43.73,33.632-71.738c0-20.273-6.492-39.057-17.502-54.387 l61.468-61.468c4.458,1.819,9.269,2.771,14.089,2.771c20.581,0,37.325-16.743,37.325-37.323 C419,37.55,402.256,20.803,381.675,20.803z M230.178,158.153c27.784,0,50.388,22.604,50.388,50.388 c0,27.784-22.603,50.388-50.388,50.388c-27.785,0-50.39-22.604-50.39-50.388C179.788,180.757,202.393,158.153,230.178,158.153z",
        viewbox: "0 0 419 419",
      },
      {
        header: "Data Management",
        snippet:
          "Create, read, update, and delete (CRUD) operations. Performance optimization. Database normalization. Other good things.",
        svg: "M256,0C136.384,0,42.667,42.176,42.667,96v320c0,53.824,93.717,96,213.333,96s213.333-42.176,213.333-96V96 C469.333,42.176,375.616,0,256,0z M448,416c0,35.307-78.848,74.667-192,74.667S64,451.307,64,416v-64.384 c34.197,32.043,106.347,53.717,192,53.717s157.803-21.675,192-53.717V416z M448,309.333C448,344.64,369.152,384,256,384 S64,344.64,64,309.333v-64.384c34.197,32.043,106.347,53.717,192,53.717s157.803-21.675,192-53.717V309.333z M448,202.667 c0,35.307-78.848,74.667-192,74.667s-192-39.36-192-74.667v-64.384C98.197,170.325,170.347,192,256,192s157.803-21.675,192-53.717 V202.667z M256,170.667c-113.152,0-192-39.36-192-74.667c0-35.307,78.848-74.667,192-74.667S448,60.693,448,96 C448,131.307,369.152,170.667,256,170.667z",
        viewbox: "0 0 512 512",
      },
      {
        header: "Security",
        snippet:
          "Identity and Access Management (IAM). Encryption. Cryptography. OWASP Top 10 threat mitigation. Sleep better at night.",
        svg: "M272.892,455.515H52.567c-8.149,0-14.785-6.636-14.785-14.786V48.641c0-8.149,6.636-14.788,14.785-14.788H325 c8.148,0,14.785,6.639,14.785,14.788v145.957c10.174-6.852,21.623-11.902,33.852-14.97V48.641C373.636,21.821,351.82,0,325,0 H52.567C25.748,0,3.93,21.821,3.93,48.641v392.088c0,26.82,21.818,48.643,48.637,48.643H275.33 c-1.43-4.985-2.438-10.137-2.438-15.572V455.515z M114.307,96.335c-9.35,0-16.928,7.579-16.928,16.927c0,9.347,7.578,16.927,16.928,16.927h113.906 c9.345,0,16.923-7.58,16.923-16.927c0-9.348-7.578-16.927-16.923-16.927H114.307z M263.254,183.951H114.307c-9.35,0-16.928,7.579-16.928,16.925c0,9.349,7.578,16.928,16.928,16.928h148.947 c9.349,0,16.927-7.579,16.927-16.928C280.181,191.53,272.603,183.951,263.254,183.951z M263.254,271.573H114.307c-9.35,0-16.928,7.581-16.928,16.929c0,9.346,7.578,16.924,16.928,16.924h148.947 c9.349,0,16.927-7.578,16.927-16.924C280.181,279.154,272.603,271.573,263.254,271.573z M263.254,393.041c3.662,0,6.869-1.404,9.638-3.387v-27.076c-2.77-1.983-5.977-3.389-9.638-3.389H114.307 c-9.35,0-16.928,7.578-16.928,16.928c0,9.347,7.578,16.924,16.928,16.924H263.254z M473.576,317.85v-33.912c0-40.71-33.09-73.803-73.787-73.803c-40.715,0-73.822,33.093-73.822,73.803v33.912 c-10.811,1.85-19.223,10.869-19.223,22.238v133.711c0,12.708,10.281,22.982,22.976,22.982H469.84 c12.694,0,23.011-10.274,23.011-22.982V340.088C492.851,328.719,484.403,319.699,473.576,317.85z M417.955,421.92 c0,10.041-8.142,18.184-18.184,18.184c-10.041,0-18.184-8.143-18.184-18.184v-29.942c0-10.045,8.143-18.183,18.184-18.183 c10.042,0,18.184,8.138,18.184,18.183V421.92z M435.093,317.105h-70.611v-33.168c0-19.463,15.85-35.305,35.307-35.305 c19.438,0,35.305,15.842,35.305,35.305V317.105z",
        viewbox: "0 0 490 490",
      },
    ];

    return { services, fadeInServiceSnippet, options };
  },
};
</script>